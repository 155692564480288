.row.my-2.mx-1.justify-content-center.table {
    border: 1px solid black;
}

.table.table-borderless tr, .jsgrid .table-borderless.jsgrid-table tr, .table.table-borderless td, .jsgrid .table-borderless.jsgrid-table td, .table.table-borderless th, .jsgrid .table-borderless.jsgrid-table th {
    border: 1px solid black;
}


td.col-8.tableDetail {
    text-wrap: wrap;
}