/* a.fc-event.fc-event-draggable.fc-event-resizable.fc-event-start.fc-event-end.fc-event-past.fc-daygrid-event.fc-daygrid-dot-event {
    background-color: green;
} */

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(0 0 0 / 24%);
}

.fc-h-event { 
    display: block;
    border: 1px solid var(--fc-event-border-color);
    background-color: var(--fc-event-bg-color);
  
    & .fc-event-main {
      color: var(--fc-event-text-color);
    }
  
    & .fc-event-main-frame {
      display: flex; 
    }
  
    & .fc-event-time {
      max-width: 100%; 
      overflow: hidden;
    }
  
    & .fc-event-title-container { 
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0; 
    }
  
    & .fc-event-title {
      display: inline-block; 
      vertical-align: top; 
      left: 0; 
      right: 0; 
      max-width: 100%; 
      overflow: hidden;
    }
  
    &.fc-event-selected:before {
      top: -10px;
      bottom: -10px;
    }
  
  }
  
  
  .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start),
  .fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-end) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
  }
  
  .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
  .fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }
  
  
  .fc-h-event:not(.fc-event-selected) .fc-event-resizer {
    top: 0;
    bottom: 0;
    width: var(--fc-event-resizer-thickness);
  }
  
  .fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start,
  .fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end {
    cursor: w-resize;
    left: calc(-0.5 * var(--fc-event-resizer-thickness));
  }
  
  .fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end,
  .fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start {
    cursor: e-resize;
    right: calc(-0.5 * var(--fc-event-resizer-thickness));
  }
  
  
  .fc-h-event.fc-event-selected .fc-event-resizer {
    top: 50%;
    margin-top: calc(-0.5 * var(--fc-event-resizer-dot-total-width));
  }
  
  .fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-start,
  .fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-end {
    left: calc(-0.5 * var(--fc-event-resizer-dot-total-width));
  }
  
  .fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-end,
  .fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-start {
    right: calc(-0.5 * var(--fc-event-resizer-dot-total-width));
  }




a.fc-event,
a.fc-event:hover {
  text-decoration: none;
}


.fc-event[href],
.fc-event.fc-event-draggable {
  cursor: pointer;
}



.fc-event {
  & .fc-event-main {
    position: relative;
    z-index: 2;
  }
}



.fc-event-dragging {

  &:not(.fc-event-selected) { 
    opacity: 0.75;
  }

  &.fc-event-selected { 
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  }

}




.fc-event {

  & .fc-event-resizer {
    display: none;
    position: absolute;
    z-index: 4;
  }

}

.fc-event:hover, 
.fc-event-selected {

  & .fc-event-resizer {
    display: block;
  }

}

.fc-event-selected {

  & .fc-event-resizer {
    border-radius: calc(var(--fc-event-resizer-dot-total-width) / 2);
    border-width: var(--fc-event-resizer-dot-border-width);
    width: var(--fc-event-resizer-dot-total-width);
    height: var(--fc-event-resizer-dot-total-width);
    border-style: solid;
    border-color: inherit;
    background: var(--fc-page-bg-color);
    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
    }

  }

}


.fc-event-selected,
.fc-event:focus {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  &:before {
    content: "";
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: "";
    background: var(--fc-event-selected-overlay-color);
    position: absolute;
    z-index: 1;

    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
  }

}

.fc {
    display: flex;
    flex-direction: column;
  
    font-size: 1em;
  
    &,
    & *,
    & *:before,
    & *:after {
      box-sizing: border-box;
    }
  
    & table {
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 1em; 
    }
  
    & th {
      text-align: center;
    }
  
    & th,
    & td {
      vertical-align: top;
      padding: 0;
    }
  
    & a[data-navlink] {
      cursor: pointer;
    }
  
    & a[data-navlink]:hover {
      text-decoration: underline;
    }
  }
  
  .fc-direction-ltr {
    direction: ltr;
    text-align: left;
  }
  
  .fc-direction-rtl {
    direction: rtl;
    text-align: right;
  }
  
  .fc-theme-standard {
    & td,
    & th {
      border: 1px solid var(--fc-border-color);
    }
  }
  

  .fc-liquid-hack {
  
    & td,
    & th {
      position: relative;
    }
  
  }

  .dailynotes {
    text-wrap: balance;
}